@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;800&display=swap");
body,
html {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}
h1 {
  text-align: left;
  font-weight: 700;
  font-size: 72px;
  margin: 3rem;
  /* background: #0000008a; */
  line-height: 110%;
  /* -webkit-text-stroke: medium; */
  /* -webkit-text-stroke-color: black; */
  text-shadow: 0px 0px 19px black;
  color: #f15743;
}
a, a:visited {
  color: #f15743;
}
body {
  color: white;
  background-color: #000000;
  opacity: 1;
}
body .twoDGif {
  position: fixed;
  width: min(60vw, 54.7619047619vh);
  display: flex;
  align-items: center;
  z-index: -1;
}
body .twoDGif img {
  width: 100%;
  height: min(80%, 87.652173913vw);
  transform: scale(5);
}
body #app section {
  height: 100vh;
}
body #app section .content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
body #app .filtering .content .filterBtn {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 15px 20px;
  margin: 3px;
  border: solid 2px white;
  border-radius: 7px;
}
body #app .filtering .content .filterBtn:hover {
  background: rgba(116, 116, 116, 0.5);
  cursor: pointer;
}
body #app .filtering .content .selected {
  background: rgba(116, 116, 116, 0.5);
}
body #app .closing {
  height: calc(50vh + 70px);
}
body #app .closing .content {
  margin-top: calc(50vh - 70px);
}
canvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  .twoDGif {
    bottom: 30vh;
    left: -10vw;
  }
}
@media only screen and (min-width: 600px) {
  .twoDGif {
    top: 30vh;
  }
}
/*# sourceMappingURL=index.9c41c9cc.css.map */
