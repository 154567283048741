@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;800&display=swap");

body,
html {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: left;
  font-weight: 700;
  font-size: 72px;
  margin: 3rem;
  /* background: #0000008a; */
  line-height: 110%;
  /* -webkit-text-stroke: medium; */
  /* -webkit-text-stroke-color: black; */
  text-shadow: 0px 0px 19px black;
  color: #f15743;
}
a, a:visited {
  color: #f15743;
}

body {
  color: white;
  // background-color: #63d1d3;
  // background: url("../assets/images/1.jpg");
  // backdrop-filter: saturate(0.01);
  // background-size: auto;
  background-color: #000000;
  opacity: 1;
  // background-image:  linear-gradient(#282828 4.800000000000001px, transparent 4.800000000000001px), linear-gradient(90deg, #282828 4.800000000000001px, transparent 4.800000000000001px), linear-gradient(#282828 2.4000000000000004px, transparent 2.4000000000000004px), linear-gradient(90deg, #282828 2.4000000000000004px, #000000 2.4000000000000004px);
  // background-size: 120px 120px, 120px 120px, 24px 24px, 24px 24px;
  // background-position: -4.800000000000001px -4.800000000000001px, -4.800000000000001px -4.800000000000001px, -2.4000000000000004px -2.4000000000000004px, -2.4000000000000004px -2.4000000000000004px;

  .twoDGif {
    position: fixed;
    //   height: 100vh;
    width: min(60vw, 80vh * 460/672);
    display: flex;
    align-items: center;
    z-index: -1;

    img {
      width: 100%;
      height: min(80%, 60vw * 672/460);
      transform: scale(5);
    }
  }

  #app {
    section {
      height: 100vh;

      .content {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
    }
    .filtering {
      .content {
        .filterBtn {
          background: rgba(0, 0, 0, 0.5);
          color: white;
          padding: 15px 20px;
          margin: 3px;
          border: solid 2px white;
          border-radius: 7px;
        }
        .filterBtn:hover {
          background: rgba(116, 116, 116, 0.5);
          cursor: pointer;
        }
        .selected {
          background: rgba(116, 116, 116, 0.5);
        }

      }
    }

    // LAST SECTION
    .closing {
      height: calc(50vh + 140px / 2);

      .content {
        margin-top: calc(50vh - 140px / 2);
      }
    }
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
}

// Mobile
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }
  .twoDGif {
    bottom: 30vh;
    left: -10vw;
    //    width: 65vw;
  }
}

// Desktop
@media only screen and (min-width: 600px) {
  .twoDGif {
    // width: 50vw;
    top: 30vh;
    //   left: 0vw;
  }
}
